document.addEventListener('DOMContentLoaded', async () => {
  const { default: lottie } = await import('lottie-web');
  let animationConfigs = [
    {
      container: document.querySelector(".th-new-hero__astro-animation"),
      path: "/homepage/astro-animation.json"
    },
    {
      container: document.querySelector(".th-new-hero__codey-animation"),
      path: "/homepage/codey-animation.json"
    },
    {
      container: document.querySelector(".th-pre-footer__koa-animation"),
      path: "/homepage/koa-animation.json"
    }
  ];
  let animations = animationConfigs.map(config =>
    lottie.loadAnimation({
      ...config,
      renderer: "svg",
      loop: true,
      autoplay: false
    })
  );
  if (typeof IntersectionObserver !== "undefined") {
    let observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        let index = animationConfigs
          .map(c => c.container)
          .indexOf(entry.target);
        let animation = animations[index];
        if (entry.isIntersecting) {
          animation.play();
        } else {
          animation.pause();
        }
      });
    });
    animationConfigs.forEach(config => observer.observe(config.container));
  } else {
    animations.forEach(animation => animation.play());
  }
});
